
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ProductionCountries from './productionCountries.vue';
import ProductionCountriesProviderDetailModal from '../modals/productionCountriesProviderDetailModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ProductionCountries
    }
})
export default class BestFootwearCombinationTable extends Vue {

    @Prop({
        default: () => new OM.BestFootwearCombination()
    }) model: OM.BestFootwearCombination;

    created() {
    }

    get selectedCompositionFormattedData() {
        let dataWithIndent = this.model.bestFootwearEstimation.footwearData.selectedComposition.percentageCompositionValues.map((item) => {
        let indentName = item.parentName ? `-- ${item.name}` : item.name;
            return {...item, indentName};
        });
        return dataWithIndent;
    }

    openDetailModal() {
        this.$opModal.show(ProductionCountriesProviderDetailModal, {
            providerName: this.model.provider.text,
            providerPrice: this.model.providerPrice,
            groupedProductionCountries: this.groupedProductionCountries
        })
    }

    get groupedProductionCountries() {
        // Primo livello di raggruppamento per 'layerName'
        const groupedByLayerName = this.model.countries.reduce((acc, country) => {
            if (!acc[country.layerName]) {
            acc[country.layerName] = [];
            }
            acc[country.layerName].push(country);
            return acc;
        }, {});

        // Secondo livello di raggruppamento per 'productionPhase' all'interno di ogni 'layerName'
        return Object.keys(groupedByLayerName).reduce((acc, layerName) => {
            acc[layerName] = groupedByLayerName[layerName].reduce((innerAcc, country) => {
                if (!innerAcc[country.productionPhase]) {
                    innerAcc[country.productionPhase] = [];
                }
                innerAcc[country.productionPhase].push(country);
                return innerAcc;
            }, {});

            return acc;
        }, {});
    }

}
